<template>
  <v-row no-gutters >
    <v-col cols="7">
      <v-menu
        ref="menu"
        v-model="menu" 
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template #activator="{ on }">
          <v-text-field
            :label="control.label"
            :value="displayDate"
            :disabled="control.disabled"
            :error-messages="errorMessages"
            readonly
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="fieldDate"
          no-title
          scrollable
          @input="menu = false"
        />
      </v-menu>
    </v-col>
    <v-col cols="5">
      <v-combobox
        v-model="fieldTime"
        label="Time"
        clearable
        :disabled="control.disabled"
        :error="!!errorMessages"
        :items="[
          { text: '7 AM', value: '07:00'}, { text: '8 AM', value: '08:00'}, { text: '9 AM', value: '09:00'}, { text: '10 AM', value: '10:00'},
          { text: '11 AM', value: '11:00'}, { text: 'Noon', value: '12:00'}, { text: '1 PM', value: '13:00'}, { text: '2 PM', value: '14:00'},
          { text: '3 PM', value: '15:00'}, { text: '4 PM', value: '16:00'}, { text: '5 PM', value: '17:00'}, { text: '6 PM', value: '18:00'},
          { text: '7 PM', value: '19:00'}, { text: '8 PM', value: '20:00'}, { text: '9 PM', value: '21:00'}, { text: '10 PM', value: '22:00'},
          { text: '11 PM', value: '23:00'}, { text: 'Midnight', value: '00:00'}, { text: '1 AM', value: '01:00'}, { text: '2 AM', value: '02:00'},
          { text: '3 AM', value: '03:00'}, { text: '4 AM', value: '04:00'}, { text: '5 AM', value: '05:00'}, { text: '6 AM', value: '06:00'},  
        ]"
        @click:clear="handleClear"
      />
    </v-col>
  </v-row>
</template>

<script>

// Mixin
import Control from './Mixin'

// Helpers
import moment from 'moment'

export default {
  name: 'Datetime',
  mixins: [ Control ],
  data() {
    return {
      menu: false,
      localErrors: '',
      timeValue: null,
      dateValue: null
    }
  },
  mounted () {
    const value = this.control.get()
    this.timeValue = value ? moment(value).format('HH:mm'): null,
    this.dateValue = value ? moment(value).format('YYYY-MM-DD') : null
  },
  computed: {
    displayDate() {
      return this.dateValue ? moment(this.dateValue).format('ddd, MMMM D') : ''
    },
    errorMessages() {
      return this.localErrors || this.control.errors
    },
    fieldDate: {
      get() {
        return this.dateValue
      },
      set(newDate) {
        // Get year, month, date
        const yy = newDate.split('-')[0]
        const mm = parseInt(newDate.split('-')[1]) - 1 // moment months are zero-indexed
        const dd = newDate.split('-')[2]
        const value = this.control.get()
        if (value) {
          // Existing value, change the date, keep the time
          this.control.set(moment(value).year(yy).month(mm).date(dd).format())
        } else {
          // New value, use current date and set the date field
          this.control.set(moment().year(yy).month(mm).date(dd).hour(8).minute(0).format())
          this.timeValue = '08:00'
        }
        this.dateValue = newDate
      }
    },
    fieldTime: {
      get() {
        return this.timeValue
      },
      set(newTime) {
        if (!newTime) {
          // Blank out time
          this.localErrors = ''
          this.timeValue = null
          return
        }

        // Normalize newTime
        newTime = typeof newTime == 'object' ? newTime.value : newTime
        // Validate time in case user entered it wrong
        const validTime = newTime.match(/(^\s*[01]?[0-9]|2[0-3]):([0-5][0-9])\s*$/)
        if (validTime) {
          // Get hours and minutes, use 00 seconds
          const hh = validTime[1]
          const mm = validTime[2]
          const ss = 0
          const value = this.control.get()
          if (value) {
            // Existing value, change the time
            this.control.set(moment(value).hours(hh).minutes(mm).seconds(ss).format())
          } else {
            // New value, use current date and set the date field
            this.control.set(moment().hours(hh).minutes(mm).seconds(ss).format())
            this.dateValue = moment().format('YYYY-MM-DD')
          }
          this.localErrors = ''
          this.timeValue = newTime
        } else {
          this.localErrors = 'Invalid time'
        }
      }
    }
  },
  methods: {
    handleClear () {
      // Also clear the time and date
      this.dateValue = ''
      this.control.set('')
    }
  }
}
</script>
