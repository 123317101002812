var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"7"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":_vm.control.label,"value":_vm.displayDate,"disabled":_vm.control.disabled,"error-messages":_vm.errorMessages,"readonly":""}},on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.fieldDate),callback:function ($$v) {_vm.fieldDate=$$v},expression:"fieldDate"}})],1)],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-combobox',{attrs:{"label":"Time","clearable":"","disabled":_vm.control.disabled,"error":!!_vm.errorMessages,"items":[
        { text: '7 AM', value: '07:00'}, { text: '8 AM', value: '08:00'}, { text: '9 AM', value: '09:00'}, { text: '10 AM', value: '10:00'},
        { text: '11 AM', value: '11:00'}, { text: 'Noon', value: '12:00'}, { text: '1 PM', value: '13:00'}, { text: '2 PM', value: '14:00'},
        { text: '3 PM', value: '15:00'}, { text: '4 PM', value: '16:00'}, { text: '5 PM', value: '17:00'}, { text: '6 PM', value: '18:00'},
        { text: '7 PM', value: '19:00'}, { text: '8 PM', value: '20:00'}, { text: '9 PM', value: '21:00'}, { text: '10 PM', value: '22:00'},
        { text: '11 PM', value: '23:00'}, { text: 'Midnight', value: '00:00'}, { text: '1 AM', value: '01:00'}, { text: '2 AM', value: '02:00'},
        { text: '3 AM', value: '03:00'}, { text: '4 AM', value: '04:00'}, { text: '5 AM', value: '05:00'}, { text: '6 AM', value: '06:00'} ]},on:{"click:clear":_vm.handleClear},model:{value:(_vm.fieldTime),callback:function ($$v) {_vm.fieldTime=$$v},expression:"fieldTime"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }